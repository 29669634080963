<template>
  <div id="home">
    <div id="head">
      <v-app-bar color="light-blue darken-2" class="rounded" flat dark>
          <h2 class="font-weight-light">Proyectos</h2>
      </v-app-bar>
    </div>
    <div id="body" class="mt-2">
      <v-row>
        <v-col cols=12>
          <v-card flat class="rounded" style="height: 80vh;" >  
              <v-container>
                <v-card-text >
                  <div v-if="loading" class="text-center">
                    <v-progress-circular
                      :size="60"
                      color="blue"
                      indeterminate
                      class="mt-4"
                    ></v-progress-circular>
                  </div>
                  <div v-else >
                    <div v-if="cantidad_proyectos !== 0 ">
                      <h2 class="font-weight-medium mt-0">Disponibles</h2>
                      <v-row>
                        <div  v-for="proyecto in proyectos">
                         <v-col cols=12>
                            <v-card
                              color="blue-grey darken-1"
                              dark
                              class="d-flex align-center mt-6 text-center"
                              height=150
                              width=200
                              link :to="{
                                name: 'FileView', 
                                params: {
                                  'data':proyecto.file_json,
                                  'numero_proyecto':proyecto.file_json.numero_proyecto, 
                                  'url_dashboard': 'dash_1'
                                }
                              }"
                            >
                              <v-row>
                                <v-col cols=12>
                                  <v-icon class="mx-auto" x-large>mdi-file-chart-outline</v-icon>
                                </v-col>
                                <v-col cols=12>
                                  <h3 class="font-weight-medium">{{proyecto.nombre}}</h3>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-col>
                        </div>
                        <v-col>
                          <v-card
                            color="primary"
                            dark
                            class="d-flex align-center mt-6 text-center"
                            height=150
                            width=200
                            link :to="{ path: '/projects/new_project'}"
                          >
                            <v-row>
                              <v-col cols=12>
                                <v-icon class="mx-auto" x-large>mdi-file-plus-outline</v-icon>
                              </v-col>
                              <v-col cols=12>
                                <h3 class="font-weight-medium">Crear proyecto</h3>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-col>
                      </v-row>
                    </div>
                    <div v-else style="padding: 150px 0; text-align: center;">
                      <h2 class="font-weight-light mt-4">No registras ningún proyecto</h2>
                      <v-card
                        color="primary"
                        dark
                        class="d-flex align-center mx-auto mt-6"
                        height=150
                        width=15%
                        link :to="{ path: '/projects/new_project'}"
                      >
                        <v-row>
                          <v-col cols=12>
                            <v-icon class="mx-auto" x-large>mdi-file-plus-outline</v-icon>
                          </v-col>
                          <v-col cols=12>
                            <h3 class="font-weight-medium">Crear proyecto</h3>
                          </v-col>
                        </v-row>
                      </v-card>
                    </div>
                  </div>
                </v-card-text>
              </v-container>
          </v-card>
        </v-col>
      </v-row>
      

      <!-- <v-layout fill-height>
      <v-card flat class="rounded mt-2 fill-height"  >
        <v-card-text class="text-center" >
          <div v-if="loading" style="padding: 15% 0; text-align: center;">
            <v-progress-circular
              :size="60"
              color="dark"
              indeterminate
              class="mt-4"
            ></v-progress-circular>
          </div>
          <div v-else>
            <div v-if="cantidad_proyectos !== 0">
              <h2 class="font-weight-light mt-4">Pro</h2>
              <v-card
                color="primary"
                dark
                class="d-flex align-center mx-auto mt-6"
                height=150
                width=10%
                link :to="{ path: '/projects/new_project'}"
              >
                <v-row>
                  <v-col cols=12>
                    <v-icon class="mx-auto" x-large>mdi-file-plus-outline</v-icon>
                  </v-col>
                  <v-col cols=12>
                    Crear proyecto
                  </v-col>
                </v-row>
              </v-card>
            </div>
            <div v-else style="padding: 200px 0; text-align: center;">
              <h2 class="font-weight-light mt-4">No registras ningún proyecto</h2>
              <v-card
                color="primary"
                dark
                class="d-flex align-center mx-auto mt-6"
                height=150
                width=10%
                link :to="{ path: '/projects/new_project'}"
              >
                <v-row>
                  <v-col cols=12>
                    <v-icon class="mx-auto" x-large>mdi-file-plus-outline</v-icon>
                  </v-col>
                  <v-col cols=12>
                    Crear proyecto
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </div>
        </v-card-text>
      </v-card> 
    </v-layout> -->
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Home',
    props:[
      'proyectos','cantidad_proyectos','loading'
    ],
    data: () => ({
      data_proyectos:[],
      
    }),
    created(){
     
    }
  }
</script>
