var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"home"}},[_c('div',{attrs:{"id":"head"}},[_c('v-app-bar',{staticClass:"rounded",attrs:{"color":"light-blue darken-2","flat":"","dark":""}},[_c('h2',{staticClass:"font-weight-light"},[_vm._v("Proyectos")])])],1),_c('div',{staticClass:"mt-2",attrs:{"id":"body"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded",staticStyle:{"height":"80vh"},attrs:{"flat":""}},[_c('v-container',[_c('v-card-text',[(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{staticClass:"mt-4",attrs:{"size":60,"color":"blue","indeterminate":""}})],1):_c('div',[(_vm.cantidad_proyectos !== 0 )?_c('div',[_c('h2',{staticClass:"font-weight-medium mt-0"},[_vm._v("Disponibles")]),_c('v-row',[_vm._l((_vm.proyectos),function(proyecto){return _c('div',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"d-flex align-center mt-6 text-center",attrs:{"color":"blue-grey darken-1","dark":"","height":"150","width":"200","link":"","to":{
                              name: 'FileView', 
                              params: {
                                'data':proyecto.file_json,
                                'numero_proyecto':proyecto.file_json.numero_proyecto, 
                                'url_dashboard': 'dash_1'
                              }
                            }}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-icon',{staticClass:"mx-auto",attrs:{"x-large":""}},[_vm._v("mdi-file-chart-outline")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(proyecto.nombre))])])],1)],1)],1)],1)}),_c('v-col',[_c('v-card',{staticClass:"d-flex align-center mt-6 text-center",attrs:{"color":"primary","dark":"","height":"150","width":"200","link":"","to":{ path: '/projects/new_project'}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-icon',{staticClass:"mx-auto",attrs:{"x-large":""}},[_vm._v("mdi-file-plus-outline")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"font-weight-medium"},[_vm._v("Crear proyecto")])])],1)],1)],1)],2)],1):_c('div',{staticStyle:{"padding":"150px 0","text-align":"center"}},[_c('h2',{staticClass:"font-weight-light mt-4"},[_vm._v("No registras ningún proyecto")]),_c('v-card',{staticClass:"d-flex align-center mx-auto mt-6",attrs:{"color":"primary","dark":"","height":"150","width":"15%","link":"","to":{ path: '/projects/new_project'}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-icon',{staticClass:"mx-auto",attrs:{"x-large":""}},[_vm._v("mdi-file-plus-outline")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"font-weight-medium"},[_vm._v("Crear proyecto")])])],1)],1)],1)])])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }